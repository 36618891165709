<template>
	<!-- container -->
	<div>
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- searchArea -->
				<div class="searchArea">
					<input
						type="text"
						title="검색어 입력"
						v-model="searchKeyword"
						placeholder="검색어를 입력해주세요."
						required="required"
						@keyup.enter="onSearch()"
						maxlength="50"
					/>
					<a @click="onSearch()" class="btnSearch">조회</a>
				</div>
				<!--// searchArea -->
				<!-- listTop -->
				<div class="listTop">
					<p class="total">
						전체 <strong>{{ viewTotal | numberComma }}</strong
						>개
					</p>
					<a @click.prevent="layerOrderOpen()" class="btnSelect">{{ orderText }}</a
					><!-- 2022-02-13 - a 태그로 변경(다른 페이지도 공통) -->
				</div>
				<!--// listTop -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- contents -->
		<div class="contents">
			<!-- tabWrap -->
			<div class="tabWrap">
				<ul>
					<li :class="curr[index]" v-for="(code, index) in codes" :key="index">
						<a @click="onChange(codes[index])">{{ codes[index].name }}</a>
					</li>
				</ul>
				<div class="tabBox">
					<!-- 건축/시설물관리 -->
					<div>
						<div class="communityList">
							<ul v-if="total > 0">
								<li v-for="(post, index) in postList" :key="index">
									<a @click="communityDetail(post.id)">
										<strong
											><pre v-if="post.title" style="font-family: 'Noto Sans KR', sans-serif;">{{
												post.title.replace(/ /g, '&nbsp;')
											}}</pre></strong
										>
										<div class="viewInfo">
											<div class="user">
												<div class="imgArea">
													<img v-if="post.creatorImageId" :src="`/file/${post.creatorImageId}`" alt="" />
												</div>
												<p>{{ post.creatorName }}</p>
											</div>
											<span class="btnViews">{{ post.views }}</span>
											<p class="date">
												<span>{{ post.createDate | dateFormatYear }}</span>
												<span>{{ post.createDate | dateFormatMonth }}</span>
											</p>
										</div>
									</a>
								</li>
							</ul>
							<ul v-else-if="noDataStatus">
								<div class="noData">
									<p>
										<img src="/resources/images/ico-no-data.svg" alt="" />
										<span>등록된 커뮤니티 글이 없습니다.</span>
									</p>
								</div>
							</ul>
							<ul v-else>
								<div class="noData">
									<p>
										<img src="/resources/images/ico-no-data.svg" alt="" />
										<strong>"{{ noDataText }}"</strong>
										<span>검색된 결과가 없습니다.</span>
									</p>
								</div>
							</ul>
						</div>
					</div>
					<!--// 건축/시설물관리 -->
				</div>
			</div>
			<!--// tabWrap -->
			<!-- btnFixed -->
			<div class="btnFriArea">
				<div class="inBox">
					<a @click="communityRegist()" class="btnEdit">글쓰기</a>
				</div>
			</div>
		</div>
		<!--// contents -->
		<!-- 하단팝업 -->
		<div :class="layerOrderClass" id="layerOrder">
			<div class="inner" style="width:320px; position: absolute;">
				<div class="cont">
					<ul>
						<li><a @click="onOrderBy('createDate')">최신순</a></li>
						<li><a @click="onOrderBy('views')">조회순</a></li>
						<li><a @click="onOrderBy('mine')">내글보기</a></li>
					</ul>
				</div>
				<a href="javascript:void(0)" name="layerOrder" @click="layerOrderClose">닫기</a>
			</div>
		</div>
		<!-- 하단팝업 -->
	</div>
	<!--// container -->
</template>
<script>
// eslint-disable-next-line no-unused-vars
import post from '@/api/post';
import code from '@/api/code';
import { showSpinner, hideSpinner, numberComma, dateFormatYear, dateFormatMonth } from '@/utils/cmm.js';
export default {
	created() {
		this.onGetMenu();
		this.sortOrderByText(this.orderBy);
	},
	mounted() {
		this.$EventBus.$on('paging', () => {
			if (this.total <= this.offset) return;
			this.onGet();
		});
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
	},
	filters: {
		numberComma,
		dateFormatYear,
		dateFormatMonth,
	},
	data() {
		return {
			groupCode: 'BORD',
			orderBy: this.$route.params.orderBy == null ? 'createDate' : this.$route.params.orderBy,
			orderText: '최신순',
			codes: [],
			searchKeyword: this.$route.params.searchKeyword == null ? '' : this.$route.params.searchKeyword,
			category: '',
			categorys: [],
			categoryName: '',
			offset: 0,
			limit: 10,
			total: 0,
			viewTotal: 0,
			postList: [],
			curr: ['', '', ''],
			isAtTheBottom: false,
			layerOrderClass: 'layerOrder layerPop botPop',
			noDataStatus: true,
			noDataText: '',
		};
	},
	methods: {
		async onCount() {
			try {
				let params = {
					searchKeyword: this.searchKeyword,
					orderBy: this.orderBy,
				};
				let res = await post.getCount(params);
				this.viewTotal = res.total;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async onGetMenu() {
			try {
				showSpinner();
				let codeList = await code.getDetail(this.groupCode);
				this.codes = codeList.data;
				this.codes.forEach(c => this.categorys.push(c.code));
				this.category = this.$route.params.category ? this.$route.params.category : this.codes[0].code;
				this.categoryName = this.codes[0].name;
				// eslint-disable-next-line no-unused-vars
				this.curr = this.curr.map(e => (e = ''));
				this.curr[this.categorys.indexOf(this.category)] = 'curr';
				await this.onGet();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onGet() {
			let params = {
				searchKeyword: this.searchKeyword,
				orderBy: this.orderBy,
				category: this.category,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				let res = await post.get(params);
				this.total = res.total;
				if (this.total > 0) {
					res.data.forEach(p => this.postList.push(p));
					this.offset += this.limit;
				}
				await this.onCount();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
			this.noDataStatus = true;
		},
		async onSearch() {
			// if (this.searchKeyword.length == 0) {
			// 	await this.$alert(`검색어를 입력해주세요.`, `커뮤니티 안내`);
			// 	return false;
			// }
			this.offset = 0;
			this.postList = [];
			await this.onGet();
			if (this.total == 0) {
				this.noDataStatus = false;
			}
			this.searchKeyword.length > 0 ? (this.noDataStatus = false) : (this.noDataStatus = true);
			if (this.noDataStatus == false) this.noDataText = this.searchKeyword;
		},
		async onChange(code) {
			this.offset = 0;
			this.postList = [];
			this.category = code.code;
			this.categoryName = code.name;
			// eslint-disable-next-line no-unused-vars
			this.curr = this.curr.map(e => (e = ''));
			this.curr[this.categorys.indexOf(this.category)] = 'curr';
			await this.onGet();
			if (this.total == 0) {
				this.noDataStatus = false;
			}
			this.searchKeyword.length > 0 ? (this.noDataStatus = false) : (this.noDataStatus = true);
			if (this.noDataStatus == false) this.noDataText = this.searchKeyword;
		},
		async onOrderBy(orderBy) {
			this.sortOrderByText(orderBy);
			this.orderBy = orderBy;
			this.offset = 0;
			this.postList = [];
			this.layerOrderClose();
			await this.onGet();
			if (this.total == 0) {
				this.noDataStatus = false;
			}
			this.searchKeyword.length > 0 ? (this.noDataStatus = false) : (this.noDataStatus = true);
			if (this.noDataStatus == false) this.noDataText = this.searchKeyword;
		},
		layerOrderOpen() {
			this.layerOrderClass += ' on';
			document.documentElement.style.overflow = 'hidden';
		},
		layerOrderClose() {
			this.layerOrderClass = 'layerOrder layerPop botPop';
			document.documentElement.style.overflow = 'auto';
		},
		communityDetail(id) {
			this.$router.push({
				name: 'communityDetail',
				params: { id: id, searchKeyword: this.searchKeyword, orderBy: this.orderBy },
			});
		},
		communityRegist() {
			this.$router.push({
				name: 'communityRegist',
				params: {
					category: this.category,
					categoryName: this.categoryName,
					searchKeyword: this.searchKeyword,
					orderBy: this.orderBy,
				},
			});
		},
		sortOrderByText(orderBy) {
			switch (orderBy) {
				case 'views':
					this.orderText = '조회순';
					break;
				case 'mine':
					this.orderText = '내글보기';
					break;
				default:
					this.orderText = '최신순';
					break;
			}
		},
	},
};
</script>
