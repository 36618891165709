// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/post';

async function get(params) {
	return axiosApi.get(`${urlPath}`, { params });
}
async function getCount(params) {
	return axiosApi.get(`${urlPath}/count`, { params });
}
async function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}
async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}
async function create(params) {
	return axiosApi.post(`${urlPath}/`, params);
}
async function update(params) {
	return axiosApi.put(`${urlPath}/`, params);
}

export default { get, getOne, getCount, remove, create, update };
