// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/code';

async function get() {
	let params = {};
	return axiosApi.get(`${urlPath}`, params);
}
async function getDetail(groupCode) {
	return axiosApi.get(`${urlPath}/${groupCode}`);
}

export default { get, getDetail };
